import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import axios from 'axios'

interface Item {
	model: string
	text: string
	desc?: string
	hide: boolean
	show_deny: boolean
}

interface NavigationItems {
	[key: string]: Item[]
}

export default function Search() {
	const routeParams = useParams()
	const query = routeParams['*']
	const [ajaxLoading, setAjaxLoading] = useState(true)
	const [navigationItems, setNavigationItems] = useState<NavigationItems>({})

	useEffect(() => {
		document.title = 'Fiber :: Search'
	}, [])

	useEffect(() => {
		let cancelTokenSource = axios.CancelToken.source()

		if (query) {
			setAjaxLoading(true)
			setNavigationItems({})
			axios
				.get(`${API_FIBER_URL}/search?query=${query}`, { cancelToken: cancelTokenSource.token })
				.then(response => response.data)
				.then(data => {
					if (data.models) {
						setNavigationItems(data.models)
					}
					setAjaxLoading(false)
				})
				.catch(error => {
					if (!axios.isCancel(error)) {
						setAjaxLoading(false)
					}
				})
		}

		return () => {
			cancelTokenSource.cancel('Request canceled')
		}
	}, [query])

	function Buttons() {
		return (
			<>
				{Object.entries(navigationItems).map(([folder, items]) => (
					<>
						<h2 className={'content-block'}>{folder}</h2>
						<div className={'content-block gridView'} style={{ marginBottom: '50px' }}>
							{items.map((item: Item) => (
								<div key={folder}>
									<Link
										key={item.model}
										to={`/m?folder=${folder}&model=${item.model}`}
										className={'modelCard'}
										style={{ display: item.hide ? 'none' : 'block' }}
									>
										<div className={'textWrapper'}>
											<p style={{ fontSize: '18px', marginBottom: '0' }}>{item.text}</p>
											{item.desc && <p style={{ marginTop: '2px' }}>{item.desc}</p>}
										</div>
									</Link>
								</div>
							))}
						</div>
					</>
				))}
			</>
		)
	}

	if (ajaxLoading) {
		return (
			<h2 className={'content-block'}>
				<LoadIndicator height={20} width={20} />
			</h2>
		)
	}

	if (Object.keys(navigationItems).length === 0) {
		return null
	}

	return <Buttons />
}
