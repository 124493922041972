import {
	ViewsPage,
	SearchPage,
	ModelPage,
	ExportsPage,
	RolesPage,
	ProcessPage,
	ImportPage,
	ReportPage,
	ReportDetailPage,
} from './pages'
import { withNavigationWatcher } from './contexts/navigation'

const routes = [
	{
		path: '/',
		element: ViewsPage,
	},
	{
		path: '/v/*',
		element: ViewsPage,
	},
	{
		path: '/m/*',
		element: ModelPage,
	},
	{
		path: '/search/*',
		element: SearchPage,
	},
	{
		path: '/processes/*',
		element: ProcessPage,
	},
	{
		path: '/imports/*',
		element: ImportPage,
	},
	{
		path: '/exports',
		element: ExportsPage,
	},
	{
		path: '/roles',
		element: RolesPage,
	},
	{
		path: '/reports/*',
		element: ReportPage,
	},
	{
		path: '/report-detail',
		element: ReportDetailPage,
	},
]

export default routes.map(route => {
	return {
		...route,
		element: withNavigationWatcher(route.element, route.path),
	}
})
