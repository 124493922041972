import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import axios from 'axios'

export default function View() {
	const routeParams = useParams()
	const folder = routeParams['*']
	const navigate = useNavigate()
	const [navigationItems, setNavigationItems] = useState([])

	useEffect(() => {
		if (folder) {
			setNavigationItems([])
			document.title = `Fiber`
			axios
				.get(`${API_FIBER_URL}/views?folder=${folder}`)
				.then(response => response.data)
				.then(data => {
					if (data.models.length > 0) {
						setNavigationItems(data.models)
						document.title = `Fiber :: ${folder}`
					} else {
						navigate('/')
					}
				})
		} else {
			navigate('/')
		}
	}, [folder])

	const breadcrumb = (folders: any) => {
		const folderArray = folders.split('/')
		let path = '' // Initialize an empty path
		return folderArray.map((folder: string, index: number) => {
			path += `/${folder}` // Append current folder and a slash to the path

			// Check if it's the last element
			if (index === folderArray.length - 1) {
				// For the last element, just return the folder name without a link
				return <span key={index}>{folder}</span>
			} else {
				// For all other elements, return them with a Link and a trailing slash
				return (
					<React.Fragment key={index}>
						<Link className="link" key={index} to={`/v${path}`}>
							{folder}
						</Link>{' '}
						/{' '}
					</React.Fragment>
				)
			}
		})
	}

	function Buttons() {
		return (
			<div className={'content-block gridView'}>
				{navigationItems.map((item: any) => (
					<Link key={item.model} to={`/m?folder=${folder}&model=${item.model}`} className={'modelCard'}>
						<div className={'textWrapper'}>
							<p style={{ fontSize: '18px', marginBottom: '0' }}>{item.text}</p>
							{item.desc && <p style={{ marginTop: '2px' }}>{item.desc}</p>}
						</div>
					</Link>
				))}
			</div>
		)
	}

	if (navigationItems.length === 0 && folder) {
		return (
			<h2 className={'content-block'}>
				<LoadIndicator height={20} width={20} />
			</h2>
		)
	}

	return (
		<React.Fragment>
			{folder && <h2 className={'content-block'}>{breadcrumb(folder)}</h2>}
			{navigationItems.length > 0 && <Buttons />}
		</React.Fragment>
	)
}
